<template>
  <div class="home">
    <h1 class="titulo">CONSULTA COBERTURA</h1>
    <div id="content" class="content">
      <div id="div-province" class="div-sec">
        <span class="text-input">Provincia</span><br>
        <select class="input-select" v-model="province" v-on:change="getLocalities()">
          <option disabled>-- Provincia --</option>
          <option v-for="(item, index) in provinces" :key="index" :label="item.name" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div id="div-locality" class="div-sec"> <!--hide-->
        <span class="text-input">Localidad</span><br>
        <select class="input-select" v-model="locality" v-on:change="getStreetTypes()">
          <option disabled>-- Localidad --</option>
          <option v-for="(item, index) in localities" :key="index" :label="item.name" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div id="div-street-type" class="div-sec"><!--hide-->
        <span class="text-input">Tipo Via</span><br>
        <select class="input-select" v-model="streetType" v-on:change="showStreet()">
          <option disabled>-- Tipo Via --</option>
          <option v-for="(item, index) in streetTypes" :key="index" :label="item.name" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div style="clear:both"></div>
      <div id="div-street" class="div-sec" style="width: 300px;margin-bottom: 1rem;">
        <span class="text-input">Dirección</span><br>
        <v-select class="vue-select3" name="select3" id="g12_element"
                  :options="streets" v-model="street" label="name"
                  :filterable="false" @search="validateStreet" :clearable="false"
                  style="border: 1px solid var(--gris-3);border-radius: 4px 4px 4px 4px;font-size: 13px;font-weight: bold;margin: 10px 5px 15px 0;">
          <template #no-options="{}">
            No se ha encontrado alguna dirección.
          </template>
          <template #option="{ postal_code, name }">
            CP: {{ showCodePostal(postal_code) }} - {{ name }}
          </template>
          <template #selected-option="{ postal_code, name }">
            CP: {{ showCodePostal(postal_code) }} - {{ name }}
          </template>
        </v-select>
      </div>
      <div style="clear:both"></div>
      <div id="div-portal" class="div-sec"><!--hide-->
        <span class="text-input">Portal / Número</span><br>
        <input class="input-portal" v-model="portal">
      </div>
      <div id="div-portal-button" class="div-sec"><!--hide-->
        <div class="button-street" v-on:click="getPortal()">Consultar Cobertura</div>
      </div>
      <div id="div-portal-button" class="div-sec"><!--hide-->
        <div class="button-street" v-on:click="reset()">Limpiar</div>
      </div>
      <div style="clear:both"></div>
      <div id="div-product" style="display: flex;" v-if="this.showPortal">
        <h1>Cobertura Adamo</h1> <img src="../assets/ico-ok.svg" id="ok-fibra" height="30" width="30"
                                      style="margin: auto 10px;">
      </div>
      <div id="div-product" style="display: flex;" v-if="this.showPortalMasMobil">
        <h1>Cobertura Más Móvil</h1> <img src="../assets/ico-ok.svg" height="30" width="30" style="margin: auto 10px;">
      </div>
      <div id="div-product" style="display: flex; " v-if="this.error">
        <h1>Satélite</h1> <img src="../assets/ico-ok.svg" id="ok-fibra" height="30" width="30"
                               style="margin: auto 10px;">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      error: false,
      province: 0,
      provinces: [],
      locality: 0,
      localities: [],
      streetType: "",
      streetTypes: [],
      streetID: 0,
      street: "",
      streets: [],
      portal: "",
      portals: [],
      product: "",
      showPortalMasMobil: false,
      showPortal: false
    }
  },
  mounted: function () {
    this.getProvinces()
  },
  methods: {
    getProvinces() {
      this.$http.get('/api/provinces').then(response => {
        if (response.data['result'] == 'ok') {
          this.provinces = response.data['data']
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    getLocalities() {
      this.$http.get('/api/localities/' + this.province).then(response => {
        if (response.data['result'] == 'ok') {
          this.localities = response.data['data']
          // document.getElementById('div-locality').classList.remove("hide")
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    getStreetTypes() {
      this.$http.get('/api/street_types').then(response => {
        if (response.data['result'] == 'ok') {
          this.streetTypes = response.data['data']
          // document.getElementById('div-street-type').classList.remove("hide")
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    showStreet() {
      this.streets = []
      this.error = false
      this.showPortal = false
      this.showPortalMasMobil = false
      this.street = ""
      this.portal = ""
    },
    validateStreet(search, loading) {
      this.error = false
      this.showPortal = false
      this.portal = ""
      this.product = ""
      if (this.locality != 0 && this.streetType && search.length >= 3) {
        loading(true)
        let payload = {
          locality: this.locality,
          street_type: this.streetType,
          street: search
        }
        this.$http.get('/api/streets', {params: payload}).then(response => {
          if (response.data['result'] == 'ok') {
            this.streets = response.data['data']
          }
          loading(false)
        }).catch((e) => {
          console.log(e)
          loading(false)
        })
      }
    },
    getPortal() {
      this.error = false
      this.showPortalMasMobil = false
      this.showPortal = false
      const vm = this
      if (this.locality != 0 && this.streetType && this.street != '' && this.portal != '') {
        let payload = {
          street: this.street.id,
          portal: this.portal,
          locality: this.locality,
          street_type: this.streetType,
          street_name: this.street.name
        }
        this.$http.get('/api/portals', {params: payload}).then(async response => {
          if (response.data['result'] === 'ok') {
            this.portals = response.data['data']
            if (response.data['access'] && response.data['data'].length !== 0) {
              vm.showPortalMasMobil = true
            }
            if (!response.data['access'] && this.portals.length !== 0) {
              await this.getProduct()
            }
            if (!response.data['access'] && response.data['data'].length === 0) {
              this.error = true
            }
          }
        }).catch((e) => {
          console.log(e)
        })
      } else {
        this.error = "Portal / Número vacío"
        // document.getElementById('div-street-error').classList.remove("hide")
      }
    },
    getProduct() {
      this.$http.get('/api/products/' + this.portals[0].product).then(response => {
        if (response.data['result'] == 'ok') {
          this.product = response.data['data'][0]
          this.showPortal = true
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    showCodePostal(value) {
      let stringValue = value.toString()
      let count = stringValue.length
      while (count < 5) {
        stringValue = '0' + stringValue
        count += 1
      }
      return stringValue
    },
    reset() {
      this.province = 0
      this.locality = 0
      this.streetType = ""
      this.streetID = 0
      this.street = ""
      this.portal = ""
      this.product = ""
      this.showPortalMasMobil = false
      this.showPortal = false
      this.error = false
    }
  }
}
</script>

<style>
.div-sec {
  float: left;
  margin: 0 15px 0 0;
}

.text-input {
  color: var(--gris-1);
}

.input-select {
  margin: 10px 5px 15px 0;
  height: 30px;
  font-size: 13px;
  font-weight: bold;
  border: 1px solid var(--gris-3);
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  padding: 0 0 0 5px;
}

.input-dir {
  margin: 10px 5px 15px 0;
  height: 30px;
  font-size: 13px;
  font-weight: bold;
  border: 1px solid var(--gris-3);
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  padding: 0 0 0 5px;
  width: 300px;
}

.button-street {
  margin: 27px 0 0 0px;
  padding: 10px;
  color: var(--blanco);
  background-color: var(--fucsia-1);
  font-size: 12px;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  cursor: pointer;
}

.div-street-error {
  color: var(--rojo);
  font-size: 15px;
}

.street {
  padding: 5px 0 0 15px;
  cursor: pointer;
  font-size: 15px;
  color: var(--gris-3);
}

.div-streets {
  margin-bottom: 15px;
}

.input-portal {
  margin: 10px 5px 15px 0;
  height: 30px;
  font-size: 13px;
  font-weight: bold;
  border: 1px solid var(--gris-3);
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  padding: 0 0 0 5px;
  width: 150px;
}

.product {
  font-size: 15px;
  color: var(--gris-3);
}
</style>