<template>
  <div class="home">
    <h1 class="titulo">CONSULTA DIRECCIONES</h1>
    <div id="content" class="content text-center">
      <div style="clear:both" class="div-sec"></div>

      <div id="div-street-error" class="div-sec div-street-error hide" style="padding-bottom: 10px;">{{ error }}</div>

      <div>
        <div style="clear:both"></div>
        <div id="div-province" class="div-sec">
          <span class="text-input">Provincia*</span><br>
          <select class="input-select" v-model="province" v-on:change="getLocalities()">
            <option disabled>-- Provincia --</option>
            <option v-for="(item, index) in provinces" :key="index" :label="item.name" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>

        <div id="div-locality" class="div-sec"> <!--hide-->
          <span class="text-input">Localidad*</span><br>
          <select class="input-select" v-model="locality" v-on:change="getStreetTypes()">
            <option disabled>-- Localidad --</option>
            <option v-for="(item, index) in localities" :key="index" :label="item.name" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>

        <div id="div-street-type" class="div-sec"><!--hide-->
          <span class="text-input">Tipo Via*</span><br>
          <select class="input-select" v-model="streetType">
            <option disabled>-- Tipo Via --</option>
            <option v-for="(item, index) in streetTypes" :key="index" :label="item.name" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div id="address">
        <div style="clear:both"></div>

        <!--        <div id="div-portal" class="div-sec">&lt;!&ndash;hide&ndash;&gt;-->
        <!--          <span class="text-input">Direccion</span><br>-->
        <!--          <textarea class="text-portal" v-model=address rows="2" cols="51" v-on:keyup="checkAddress()"></textarea>-->
        <!--        </div>-->

        <!--        <div id="div-portal-button" class="div-sec">&lt;!&ndash;hide&ndash;&gt;-->
        <!--          <div id="btn-address" class="button-street" @click="checkAddress()">Validar Direccion</div>-->
        <!--        </div>-->
      </div>
      <!--  <hr>-->
      <div id="g12">
        <div style="clear:both">
          <div id="div-portal" class="div-sec"><!--hide-->
            <span class="text-input">Dirección*</span><br>
            <v-select class="vue-select3" name="select3" id="g12_element"
                      :options="g12" v-model="g12_element" label="address"
                      :filterable="false" @search="checkAddress" @input="domicilios" :clearable="false"
                      style="border: 1px solid var(--gris-3);border-radius: 4px 4px 4px 4px;font-size: 13px;font-weight: bold;"/>
            <br>

            <div id="div-street-type" class="div-sec"><!--hide-->
              <span class="text-input">Numero*</span><br>
              <input class="input-portal" v-model=number v-on:input="domicilios()">
            </div>

            <div id="div-street-type" class="div-sec"><!--hide-->
              <span class="text-input">Piso</span><br>
              <input class="input-portal" v-model="flat">
            </div>

            <div id="div-street-type" class="div-sec"><!--hide-->
              <span class="text-input">Puerta</span><br>
              <input class="input-portal" v-model="door">
            </div>

            <div id="div-street-type" class="div-sec"><!--hide-->
              <span class="text-input">Escalera</span><br>
              <input class="input-portal" v-model="stairs">
            </div>
          </div>

          <!--          <div id="div-portal-button" class="div-sec">&lt;!&ndash;hide&ndash;&gt;-->
          <!--            <div id="btn-address" class="button-street" @click="domicilios()">Obtener Direcciones</div>-->
          <!--          </div>-->

        </div>
      </div>
      <!--  <hr>-->
      <div id="homeId">
        <div style="clear:both">
          <!--          <div>Hogar</div>-->
          <!--          <select class="input-select" v-model="homeId_element">-->
          <!--            <option>Seleccione un elemento...</option>-->
          <!--            <option v-for="value in homeId" v-bind:value="value.homeId" :key="value.homeId">{{ value.homeId }} - -->
          <!--              {{ value.address }}-->
          <!--            </option>-->
          <!--          </select>-->
        </div>
        <hr>
        <div id="div-portal-button" class="div-sec text-center">
          <div id="btn-g12" class="button-street" @click="getHomeId()">Aceptar</div>
        </div>
        <div id="div-portal-button" class="div-sec">
          <div id="btn-g12" class="button-street" @click="reset()">Resetear</div>
        </div>
      </div>
      <div style="clear:both"></div>
    <br>
    <div id="div-street-result" class="hide">
      <div style="display: flex;" v-show="is_fiber"><h1>Fibra</h1><img alt="" src="../assets/ico-ok.svg" height="30" width="30" style="margin: auto 10px;"></div>
      <div style="display: flex;" v-show="is_sat"><h1>Satelite</h1><img alt="" src="../assets/ico-ok.svg" height="30" width="30" style="margin: auto 10px;"></div>
      <div style="display: flex;" v-show="is_wire"><h1>Internet En Casa 4G</h1><img alt="" src="../assets/ico-ok.svg" height="30" width="30" style="margin: auto 10px;"></div>
    </div>
  </div>
  </div>
</template>

<script>
let axios = require("axios");
export default {
  name: "CheckAddress",
  props: {
    msg: String
  },
  data() {
    return {
      address: '',
      number: '',
      g12: [],
      g12_element: '',
      homeId: [],
      homeId_element: '',
      fiber: '',
      error: "",
      province: 0,
      provinces: [],
      locality: 0,
      localities: [],
      streetType: "",
      streetTypes: [],
      streetID: 0,
      street: "",
      streets: [],
      flat: '',
      door: '',
      stairs: '',
      is_fiber: false,
      is_sat: false,
      is_wire: false
    }
  },
  mounted: function () {
    this.getProvinces()
    this.coverageAuthentication()
  },
  methods: {
    coverageAuthentication(refresh_token=false){
      if (!localStorage.getItem('access_token') || localStorage.getItem('access_token') === 'undefined') {
        this.authenticate(refresh_token)
      }
    },
    async authenticate(refresh_token) {
      console.log(refresh_token)
      let params = {}
      if (refresh_token && localStorage.getItem('refresh_token') !== 'undefined'){
        params = {refreshToken: localStorage.getItem('refresh_token')}
      }
      await this.$http.get("/api/coverage-auth", {
              headers: params
            })
            .then(response => {
              localStorage.setItem('access_token', response.data["access_token"])
              localStorage.setItem('refresh_token', response.data["refresh_token"])
            })
            .catch(e => {
              console.log(e)
            });
    },
    async checkAddress(search, loading) {
      if (search.length >= 3 && this.province !== 0 && this.locality !== 0 && this.streetType !== 0) {
        let province = ''
        loading(true)
        this.provinces.forEach(item => {
          if (item.id === this.province) {
            province = item.name
          }
        })

        let locality = ''
        this.localities.forEach(item => {
          if (item.id === this.locality) {
            locality = item.name
          }
        })

        let streetType = ''
        this.streetTypes.forEach(item => {
          if (item.id === this.streetType) {
            streetType = item.name
          }
        })

        let address = streetType + ' ' + search + ', ' + locality + ', ' + province
        const vm = this
        await this.$http.get("/api/check-address", {
              params: {address: address},
              headers: {Authorization: localStorage.getItem('access_token')}
            })
            .then(response => {
              document.getElementById('div-street-error').classList.add("hide")
              document.getElementById('div-street-result').classList.add("hide")
              vm.g12 = response.data
              setInterval(function () {
                loading(false)
              }, 600)
            })
            .catch(async e => {
              console.log(e)
              console.log('asd', e.response.status)
              if (e.response.status === 401) {
                console.log('asd', e.status)
                await this.authenticate(true)
                await this.checkAddress(search, loading)
              }
              this.error = 'Direccion invalida.'
              document.getElementById('div-street-error').classList.remove("hide")
              loading(false)
            });
      }
    },
    domicilios() {
      if (this.g12_element.g12 !== '' && this.number !== '') {
        axios
            .get("api/check-places", {
              params: {
                g12: this.g12_element.g12,
                number: this.number,
              },
              headers: {Authorization: localStorage.getItem('access_token')}
            })
            .then(response => {
              this.homeId = response.data
              document.getElementById('div-street-error').classList.add("hide")
              document.getElementById('div-street-result').classList.add("hide")
              this.homeId_element = this.homeId[0].homeId
              // this.getHomeId()
            })
            .catch(async e => {
              console.log('xdxd', e)
              if (e.response.status === 401) {
                await this.authenticate(true)
                this.domicilios()
              }
              console.log(e)
              this.error = 'G12 invalida.'
              document.getElementById('div-street-error').classList.remove("hide")
            });
      }
    },
    getHomeId() {
      document.getElementById('div-street-result').classList.add("hide")
      this.resetResults()
      axios
          .get("/api/check-home-id/" + this.homeId_element, {
              headers: {Authorization: localStorage.getItem('access_token')}
            })
          .then(response => {
            this.fiber = response.data
            document.getElementById('div-street-error').classList.add("hide")
            document.getElementById('div-street-result').classList.remove("hide")
            if (Object.keys(this.fiber.fiber).length !== 0) {
              this.is_fiber = true
            }
            if (Object.keys(this.fiber["fixedWireless"]).length !== 0) {
              this.is_wire = true
            }
            if (this.fiber["satellite"]["hasCoverage"]) {
              this.is_sat = true
            }
          })
          .catch(async e => {
            console.log(e)
            if (e.response.status === 401) {
              await this.authenticate(true)
              this.getHomeId()
            }
            this.error = 'Hogar invalido'
          });
    },
    reset() {
      this.address = ''
      this.number = ''
      this.g12 = []
      this.g12_element = ''
      this.homeId = []
      this.homeId_element = ''
      this.fiber = ''
      this.error = ''
      this.province = ''
      this.locality = ''
      this.localities = []
      this.streetType = ''
      this.streetTypes = []
      this.flat = ''
      this.door = ''
      this.stairs = ''
      this.message = []
      document.getElementById('div-street-error').classList.add("hide")
      document.getElementById('div-street-result').classList.add("hide")
      this.resetResults()
    },
    resetResults(){
      this.is_fiber = false
      this.is_wire = false
      this.is_sat = false
    },
    getProvinces() {
      this.$http.get('/api/provinces').then(response => {
        if (response.data['result'] === 'ok') {
          this.provinces = response.data['data']
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    getLocalities() {
      this.$http.get('/api/localities/' + this.province).then(response => {
        if (response.data['result'] === 'ok') {
          this.localities = response.data['data']
          document.getElementById('div-locality').classList.remove("hide")
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    getStreetTypes() {
      this.$http.get('/api/street_types').then(response => {
        if (response.data['result'] === 'ok') {
          this.streetTypes = response.data['data']
          document.getElementById('div-street-type').classList.remove("hide")
        }
      }).catch((e) => {
        console.log(e)
      })
    },
  }
}
</script>

<style scoped>

</style>