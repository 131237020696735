<template>
  <div class="navbar">
    <img class="logo" alt="eurona" src="../assets/logo-eurona.svg">
    <div class="telefono-pc"></div>
  </div>
</template>

<script>
export default {
  name: 'ToggleNav',
  data () {
    return {
    }
  },  
  methods: {
  },
  computed: {
  }  
}
</script>

<style>
.logo {
  margin: 10px;
  width: 150px;
}

@media (min-width: 768px) {
  .logo {
    width: 120px;
  }

  .navbar {
    margin: 0 auto;
    width: var(--widthContent);
  }
  .telefono-pc {
    display: block;
    font-weight: bold;    
    color: var(--negro);    
    position: fixed;
    top: 15px;
    font-size: 20px;
    text-align: center;
    margin-left: 340px;
  }
}
</style>