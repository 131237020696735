<template>
  <div id="app">
    <toggle-nav></toggle-nav>
    <router-view/>
  </div>
</template>

<script>
import ToggleNav from './components/ToggleNav.vue'

export default {
  name: 'app',
  mounted: function () {
  },
  methods: {
  },
  computed: {
  },
  components: { ToggleNav }
}
</script>

<style>
:root {
    --rojo: #FF0000;
    --verde: #12BA18;
    --azul-1: #0086B4;
    --azul-2: #41B6E6;
    --azul-3: #7EE8FF;
    --fucsia-1: #FF008C;
    --gris-1: #030303;
    --gris-2: #2E2E2E;
    --gris-3: #575757;
    --gris-4: #B1B1B1;
    --gris-5: #CECECE;
    --gris-6: #E5E5E5;
    --blanco: #FFFFFF;

    --widthContent: 1024px;
}

body,html {
    width: 100%;
    border: 0;
    margin: 0;
    font-family: Montserrat, sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

.show {
  display: block!important;
}

.hide {
  display: none!important;
}

.titulo {
  margin: 0;
  padding: 15px 0;
  color: var(--blanco);
  background-color: var(--azul-1);
  background-image: linear-gradient(var(--azul-1), var(--azul-2));
  text-align: center;
  font-size: 25px;
}

.titulo-pequeño {
  font-weight: 100;
}

.content {
  clear: both;
  padding: 15px;
}

@media (min-width: 768px) {
  .content {
    margin: 0 auto;
    width: var(--widthContent);
  }
}

@media (max-width: 768px) {
  .titulo {
    font-size: 20px;
  }  
}
</style>
