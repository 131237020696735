import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vSelect from 'vue-select'
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.use(VueAxios, axios)
Vue.component('v-select', vSelect)
Vue.component('v-select', VueSelect.VueSelect)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
